<template>
  <section id="login" v-if="!global.authenticated">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">
          <div class="login-field-container">
            <p>{{loginFields.error}}</p>
            <p v-if="regPage==5" >{{pswSuccess}}</p>
            <p v-if="regPage==0" ><input v-model="loginFields.username" required type="text" class="form-control login-input" placeholder="Электронная почта"></p>
            <p v-if="regPage==0" ><input v-model="loginFields.password" required type="password" class="form-control login-input" placeholder="Пароль"></p>
            <p v-if="regPage==0" ><input @click="sendLogin()" type="button" class="btn btn-success main-btn form-control text-uppercase login-input" value="Вход"></p>

            <p v-if="regPage==0" @click="regPage=1" class="foget-psw">Забыли пароль?</p>
            <p v-if="regPage==0" ><input @click="regPage=2" type="button" class="btn btn-success main-btn form-control text-uppercase login-input" value="Регистрация"></p>

            <p v-if="regPage==2" ><input v-model="loginFields.fio" required type="text" class="form-control login-input" placeholder="Как к вам обращаться"></p>
            <p v-if="regPage==2" ><input v-model="loginFields.phone" required type="text" class="form-control login-input" placeholder="Телефон"></p>
            <p v-if="regPage==1||regPage==2" ><input v-model="loginFields.email" required type="text" class="form-control login-input" placeholder="Электронная почта"></p>
            <p v-if="regPage==2" ><input v-model="loginFields.psw" required type="password" class="form-control login-input" placeholder="Пароль"></p>
            <p v-if="regPage==1" ><input @click="sendRestorePswEmail()" type="button" class="btn btn-success main-btn form-control text-uppercase login-input" value="Сбросить пароль"></p>
            <div v-if="regPage==2" class="d-flex justify-content-center">
              <div class="booking-widget-agree">
                <input type="checkbox" v-model="loginFields.agreeValue">
                <router-link to="/agreement2" ><div class="booking-widget-agree-a">Я даю согласие на обработку<br>персональных данных</div></router-link>
              </div>
            </div>
            <p v-if="regPage==2" ><input @click="registration()" type="button" class="btn btn-success main-btn form-control text-uppercase login-input" value="Зарегистрироваться"></p>
            <p v-if="regPage==1||regPage==2" ><input @click="regPage=0; loginFields.error=''; " type="button" class="btn btn-success main-btn form-control text-uppercase login-input" value="Назад"></p>

          </div>
        </div>

      </div>
    </div>
  </section>


</template>

<style>
#login{
  background: url('../images/home-bg.jpg') no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  /*height: calc(100% - 145px);*/
}

#login .container{
  width: 240px;
}
.login-field-container{
  width: 240px;
}
.login-input{
  width: 240px;
  display: inline-block;
}
.foget-psw{
  color: #fff;
  cursor: pointer;
}
.btn-success{
  border-width: 0px;
  background: #28a7e9;
}
.btn-success:hover{
  border-width: 0px;
  background: #228ec7;
}
</style>

<script>
import axios from 'axios'
import Toast from '@/js/toast.min.js'
import {global} from "@/global.js";

export default {
  name: 'LoginView',
  data() {
    return {
      global: global,
      //page: 0,
      regPage: 0,
      loginFields: {username:"", password:"",error:"", agreeValue: false},
      psw:{},
      pswErorr:"",
      pswSuccess:""
    }
  },
  props: {
    page: {
      type: Number,
      default: 0,
      required: false
    }
  },
  mounted() {
    this.regPage=this.page;
  },

  methods: {

    sendLogin() {
      this.loginFields.error="";
      const params = new URLSearchParams();
      params.append('username', this.loginFields.username);
      params.append('password', this.loginFields.password);
      axios
          .post(global.value.url + "login", params, {withCredentials: true})
          .then(() => {
            global.value.authenticated=true;
            axios.get(global.value.url + "lk/account").then(response => {
              global.value.account = response.data;
              this.$parent.load();
            });
          })
          .catch(() => {
            this.loginFields.error="Не верный логин или пароль";
            new Toast({title: false,text: 'Не верный логин или пароль',theme: 'danger',autohide: true,interval: 10000});
          })
          .finally(() => (this.loading = false));
    },
    sendRestorePswEmail(){
      this.loginFields.error = "";
      this.loading = true;
      axios.get(global.value.url + "send-restore-psw-email?email="+this.loginFields.email).then(response => {
        this.pswSuccess = response.data.text;
        this.regPage=5;
      })
          .catch(error => {
            this.loginFields.error = error.response.data.message;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    registration(){
      if(!this.loginFields.agreeValue){
        this.loginFields.error = "Нужно согласиться с обработкой персональных данных";
        return;
      }
      this.loginFields.error = "";
      this.loading = true;
      axios
          .post(global.value.url + "registration", this.loginFields)
          .then(response => {
            this.pswSuccess = response.data.text;
            this.errored = false;
            this.regPage=5;
          })
          .catch(error => {
            this.loginFields.error = error.response.data.message;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  }
}
</script>
