<template>
  <nav class="navbar navbar-default navbar-fixed-top templatemo-nav" role="navigation">
    <div class="container" @click="closeMenu()">
      <div class="navbar-header">
        <button class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
          <span class="icon icon-bar"></span>
          <span class="icon icon-bar"></span>
          <span class="icon icon-bar"></span>
        </button>
        <router-link to="/" class="navbar-brand booking-easy-logo" @click="global.lk=false">
          <img src="https://bookingeasy.ru/images/bookingeasy2.svg">
        </router-link>
      </div>
    <div id="mainMenu" class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-right text-uppercase">
        <li v-if="!global.lk">
          <router-link to="/"
          @click="global.lk=false">Главная
        </router-link>
      </li>
      <!--li v-if="!global.lk"><router-link to="/#feature">Возможности</router-link></li-->
      <li v-if="!global.lk">
        <router-link to="/#download">Примеры</router-link>
      </li>
      <li v-if="!global.lk">
        <router-link to="/#pricing">Цены</router-link>
      </li>
      <li v-if="!global.lk">
        <router-link to="/#faq">Ответы на вопросы</router-link>
      </li>
      <li v-if="!global.lk">
        <router-link to="/#contact">Контакты</router-link>
      </li>
      <!--li ">
        <router-link to="/doc">Документация</router-link>
      </li-->
      <li v-if="!global.lk && global.authenticated">
        <router-link to="/orders">Кабинет</router-link>
      </li>
      <li v-if="!global.lk && !global.authenticated">
        <router-link to="/orders">Вход/Регистрация</router-link>
      </li>
      <li v-if="global.lk && global.authenticated">
        <router-link to="/orders">Заказы</router-link>
      </li>
      <li v-if="global.lk && global.authenticated && (global.account.name=='admin' || global.account.name=='bdh@deworkacy.ru')">
        <router-link to="/clients">Клиенты</router-link>
      </li>
      <li v-if="global.lk && global.authenticated">
        <router-link to="/balance">Баланс</router-link>
      </li>
      <li v-if="global.lk && global.authenticated">
        <router-link to="/setup">Настройки</router-link>
      </li>
      <li v-if="global.lk && global.authenticated">
        <router-link to="/doc/docConnectView">Помощь</router-link>
      </li>
      <li v-if="global.lk && global.authenticated">
        <a @click="logout()">Выход
      </a>
    </li>
  </ul>
</div>
</div>
</nav>

<div id="router-view-div" @click = "closeMenu()" >
    <router-view/>
    </div>


<cookieNotification></cookieNotification>


</template>

<style>
body{
    color: #000;
}
booking-widget {
  --booking-widget-background-color: #e1f4fd;
  --booking-widget-card-background-color: #fff;
  --booking-widget-main-btn-color: #165490;
  --booking-widget-main-btn-text-color: #fff;
  --booking-widget-agree-a-color: #165490;
  --booking-widget-booked-cell-color: #165490;
  --booking-widget-processing-cell-color: #165490;
  --booking-widget-free-cell-color: #fff;
  --booking-widget-use-cell-color: #84d2f5;
  --booking-widget-free-cell-hover-color: #e1f4fd;
}

#router-view-div{
  margin-top: 70px;
}

.navbar-default {
  background: #fff;
  border: none;
  box-shadow: 0px 2px 8px 0px rgba(50, 50, 50, 0.08);
  margin: 0 !important;
}

.navbar-default .navbar-brand {
  color: #28a7e9;
  font-size: 30px;
  font-weight: bold;
  height: 70px;
  line-height: 35px;
}

.navbar-default .nav li a {
  color: #666;
  font-size: 13px;
  font-weight: bold;
  height: 70px;
  line-height: 40px;
  cursor: pointer;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus,
.navbar-default .nav li a.current {
  color: #28a7e9;
}

.navbar-default .navbar-toggle {
  border: none;
  padding-top: 20px;
}

.navbar-default .navbar-toggle .icon-bar {
  background: #28a7e9;
  border-color: transparent;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: transparent
}


.booking-easy-logo {

  /*width: 250px;*/
}


.main-btn {
  background-color: rgb(40, 167, 233);
  border-color: rgb(40, 167, 233);
  border-radius: 100px;
  color: #fff;
  /*display: flex;
  align-items: center;
  justify-content: center;*/
}

.main-btn:focus {
  background-color: rgb(40, 167, 233);
  border-color: rgb(40, 167, 233);
  color: #fff;
}
.main-btn:hover {
  color: #fff;
}


#cookie_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  background-color: white;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
}

#cookie_notification p {
  margin: 0;
  text-align: left;
}

.cookie_accept {
  margin: 0 0 0 10px;
  border-radius: 100px;
}


/*@media (min-width: 576px) {
  #cookie_notification.show {
    display: flex;
  }


}

@media (max-width: 575px) {
  #cookie_notification.show {
    display: block;
    text-align: left;
  }

  .cookie_accept {
    margin: 10px 0 0 0;
  }
}*/
</style>

<script>
import axios from "axios";
import {global} from "./global.js";
import cookieNotification from '@/components/СookieNotification.vue'

export default {
  name: 'App',
  components: {
    cookieNotification
  },
  data() {
    return {
      global: global,
      page: 0
    }
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    for (let param of urlParams.entries()) {
      if(param[0]=='url')global.value.url = param[1];
    }
    document.cookie = "SESSION=NmE4ZTRhODEtOTFhMS00MDdjLWJiYmMtNWIzOTA4MTIwZTM5; Path=/; HttpOnly; SameSite=Lax";
    axios.get(global.value.url + "test").then(response => {
      if (response.data == "ok") {
        global.value.authenticated = true;
        global.value.lk = true;
        this.periodicTest();
        axios.get(global.value.url + "lk/account").then(response => {
          global.value.account = response.data;
        });
      }
    });

  },

  methods: {
    closeMenu() {
      //$(".navbar-collapse").collapse('hide');
      //setTimeout(() => { $(".navbar-collapse").collapse('hide'); }, 100);
      var element = document.getElementById('mainMenu');
      element.style.height = null;
      element.classList.remove("in");
      element.ariaExpanded = "false";
      //<div className="navbar-collapse collapse" aria-expanded="false" style="height: 1px;">
      //<div className="navbar-collapse collapse in" aria-expanded="true" style="">


    },
    periodicTest() {
      setInterval(function () {
        axios.get(global.value.url + "test")
            .then(response => {
              if (response.data == "error") {
                //this.page=0;
                global.value.authenticated = false;
              }
            })
            .catch(() => {
              //this.page=0;
              global.value.authenticated = false;
            })
      }.bind(this), 600000);
    },
    logout() {
      axios.get(global.value.url + "logout").then(() => {
        global.value.authenticated = false;
        global.value.lk = false;
        global.value.account = {key:"ключ(появится после входа в личный кабинет)",botKey:"(появится после входа в личный кабинет)", subjects:[]};
        this.$router.push("/");
        //this.page=0;
      });
    }
  }
}
</script>


<style>

</style>
