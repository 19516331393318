<template>
  <LoginView/>
  <section id="client" v-if="global.authenticated && (global.account.name=='admin' || global.account.name=='bdh@deworkacy.ru')">
    <div class="overlay">
      <div class="container">
        <div class="d-flex align-items-start flex-column justify-content-center">
          <table className="main-setings">
            <tr>
              <td className="cell">Имя:</td>
              <td className="cell"><input type="text" v-model="client.name"/></td>
            </tr>
            <tr>
              <td className="cell">Телефон:</td>
              <td className="cell"><input type="text" v-model="client.phone"/></td>
            </tr>
            <tr>
              <td className="cell"></td>
              <td className="cell">
                <input class="btn btn-sm main-btn text-uppercase" @click="addClient()" value="Добавить">
              </td>
            </tr>
          </table>
          <br><br>
          <table>
            <tr>
              <td class="cell">Имя</td>
              <td class="cell">Телефон</td>
              <!--td class="cell">Дата регистрации</td-->
              <td></td>
            </tr>
            <tr v-for="(p,index) in clients"
            :key="index">
            <td class="cell">{{p.name}}</td>
            <td class="cell">{{p.phone}}</td>
            <!--td class="cell">{{getDate(p.createdDt)}}</td-->
            <td class="cell">
              <input class="btn btn-sm main-btn text-uppercase" @click="getClientUri(p.id)" value="Получить ссылку">
            </td>
          </tr>
        </table>


</div>
</div>
</div>
</section>
</template>

<style>
  #client .overlay{
  background: none;
}
  #client table{
  background: #fff;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
}
#client .cell{
  height: 52px;
  padding: 1px 16px;
  text-align: right;
}
#client .modal-dialog{
  width: 400px;
}
#client .modal-dialog .btn-primary{
  width: 100%;
}
#client .modal-dialog input{
  width: 100%;
}
</style>

<script>
import axios from 'axios'
import Toast from '@/js/toast.min.js'
import LoginView from '@/views/LoginView.vue'
import {global} from "@/global.js";

export default {
  name: 'LkBalanceView',
    client:{name:"",phone:""},
  components: {
    LoginView
  },
  data() {
    return {
      global: global,
      account:{subjects:[]},
      clients:[],
      client: {name: "", phone: ""}
    }
  },

  mounted() {
    global.value.lk=true;
    if(global.value.authenticated) {
      this.load();
    }else {
      setTimeout(function () {
        if (global.value.authenticated) {
          this.load();
        }
      }.bind(this), 1000);
    }
  },


  methods: {
    load(){
      this.getAccount();
      this.getClients();
    },
    getAccount(){
      axios.get(global.value.url + "lk/account").then(response => {
        this.account = response.data;
      });
    },
    getClients(){
      axios
          .get(global.value.url + "lk/client")
          .then(response => {
            this.clients=response.data;
          });
    },

    getClientUri(id){
        axios
        .get(global.value.url + "lk/client/uri?clientId="+id)
        .then(response => {
          if(response.data.success){
            navigator.clipboard.writeText(response.data.text);
          }else{
            new Toast({
              title: false,
              text: response.data.text,
              theme: 'danger',autohide: true,interval: 10000
            });
          }
      });
    },
    getDate(d){
      var date = new Date(d);
      return ('0' + (date.getDate())).slice(-2)+'.'+('0' + (date.getMonth() + 1)).slice(-2)+'.'+date.getFullYear()+' '+('0' + date.getHours()).slice(-2)+':'+('0' + date.getMinutes()).slice(-2)+':'+('0' + date.getSeconds()).slice(-2);
    },
    addClient(){
      this.loading = true;
      this.accountErorr = "";
      axios
          .post(global.value.url + "lk/client", this.client)
          .then(response => {
            if(response.data.success){
              navigator.clipboard.writeText(response.data.text);
              this.getClients();
            }else{
              new Toast({
                title: false,
                text: response.data.text,
                theme: 'danger',autohide: true,interval: 10000
              });
            }
            this.errored = false;
          })
          .catch(() => {
            this.accountErorr="Ошибка сохранения";
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    }
  }
}
</script>
